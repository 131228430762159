<template>
  <div class="faq-wrapper" :style="containerStyle">
    <div class="h6 main" :style="titleStyle">FAQ</div>
    <div class="faq-list">
      <div v-for="(item, index) in faq" :key="index" class="faq-item">
        <div @click="toggleAnswer(index)" class="body3-medium main question">
          <div>
            {{ item.question }}
          </div>
          <img :src="`/static/icon/u_angle-${activeIndex === index ? 'up' : 'down'}-gray.svg`" />
        </div>
        <div v-if="activeIndex === index" class="body3 sub3 answer">
          {{ item.answer }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProductFaq',
  props: {
    faq: {
      type: Array,
      required: true,
    },
    faqContainerStyle: {
      type: Object,
    },
    faqTitleStyle: {
      type: Object,
    },
  },
  data() {
    return {
      activeIndex: null,
    };
  },
  computed: {
    containerStyle() {
      if (this.faqContainerStyle) {
        return this.faqContainerStyle;
      }
      return {};
    },
    titleStyle() {
      if (this.faqTitleStyle) {
        return this.faqTitleStyle;
      }
      return {};
    },
  },
  methods: {
    toggleAnswer(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~assets/css/lp_main'

.faq-wrapper
  display flex
  flex-direction: column
  gap 25px
  padding 0 0 48px

.faq-list
  display flex
  flex-direction: column

.faq-item
  border-bottom 1px solid #e0e0e0
  padding 20px 16px


.question
  display flex
  align-items center
  justify-content space-between
  cursor pointer


.answer
  margin-top 20px
  white-space: pre-line

@media (max-width: 425px)
  .faq-wrapper
    padding 0 16px 24px
</style>
